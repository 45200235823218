import React from "react";
import "./App.scss";

const Logo = () => (
  <div className="logo">
    <h1 className="main">Mario Campione</h1>
    <span className="sub"></span>
  </div>
);

const FreeTime = () => (
  <div className="verbose free-time">
    <p className="text">
      <span role="img" aria-label="code">
        💻
      </span>
      <span role="img" aria-label="sailing">
        ⛵
      </span>
      <span role="img" aria-label="piano">
        🎹
      </span>
      <span role="img" aria-label="guitar">
        🎸
      </span>
      <span role="img" aria-label="books">
        📚
      </span>
      <span role="img" aria-label="painting">
        🎨
      </span>
      <span role="img" aria-label="volleyball">
        🏐
      </span>
      <span role="img" aria-label="basketball">
        🏀
      </span>
      <span role="img" aria-label="thai boxe">
        🥊
      </span>
      <span role="img" aria-label="swimming">
        🏊
      </span>
      <span role="img" aria-label="cooking">
        🍳
      </span>
      <span role="img" aria-label="travelling">
        ✈
      </span>
      <span role="img" aria-label="beach">
        ️🏖
      </span>
    </p>
  </div>
);

const Intro = () => (
  <div className="intro">
    <div className="text">
      <span className="hi">Hi there!</span>
    </div>
    <div className="text">
      <span className="iam">
        I am a <span className="highlight">freelance software engineer</span>{" "}
        living in <span className="highlight">Switzerland</span>.
      </span>{" "}
    </div>
    <div className="text">
      <span className="ido">
        <span className="pre">I build</span>{" "}
        <span className="highlight">modern apps</span>.
      </span>
    </div>
  </div>
);

const MyJob = () => (
  <div className="verbose">
    <p className="text">
      I work with the technology you need to build complex, modern applications,
      from <span className="highlight">e-commerce platforms</span> to{" "}
      <span className="highlight">social networks</span> or{" "}
      <span className="highlight">mobile apps</span>.
    </p>
    <p className="text">
      <span className="pre">Building such applications is</span>{" "}
      <span className="highlight special">my job</span>.
    </p>
  </div>
);

const WhereILive = () => (
  <div className="map">
    <div className="marker zug">
      <div className="icon" />
      <div className="label">zug</div>
    </div>

    <img className="outline" src="ch.svg" alt="Zug, Switzerland" />

    <div className="caption">(Yeah, I live right there.)</div>
  </div>
);

const JobDetails = () => (
  <div className="verbose">
    <p className="text">For example, take this website.</p>
    <p className="text">
      This is just a simple page, but it's been built with{" "}
      <a
        className="highlight"
        href="https://reactjs.org/"
        target="_blank"
        rel="noopener noreferrer"
      >
        React
      </a>
      , using{" "}
      <a
        className="highlight"
        href="https://sass-lang.com/documentation/syntax#scss"
        target="_blank"
        rel="noopener noreferrer"
      >
        SCSS
      </a>{" "}
      for styling and{" "}
      <a
        className="highlight"
        href="https://www.npmjs.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        NPM
      </a>{" "}
      for handling software dependencies.
    </p>
    <p className="text">
      It's been built with some of the{" "}
      <span className="highlight">industry standards</span> in web and mobile
      software development.
    </p>
    <p className="text">And there's a whole bunch of 'em...</p>
  </div>
);

const Skills = () => (
  <div className="skills">
    <ul className="list">
      <li className="item g1">javascript</li>
      <li className="item">typescript</li>
    </ul>

    <ul className="list">
      <li className="item g1">react</li>
      <li className="item g1">vue</li>
      <li className="item g2">html</li>
      <li className="item">css</li>
    </ul>

    <ul className="list">
      <li className="item">reactnative</li>
      <li className="item g2">android</li>
      <li className="item g1">ios</li>
    </ul>

    <ul className="list">
      <li className="item g1">java</li>
      <li className="item">springboot</li>
      <li className="item g1">hybris</li>
    </ul>

    <ul className="list">
      <li className="item g2">npm</li>
      <li className="item">yarn</li>
      <li className="item g1">git</li>
      <li className="item g2">github</li>
    </ul>
  </div>
);

const ReachOut = () => (
  <div className="action">
    <p className="text">Got projects I can help with?</p>
    <p className="cta">
      <a
        className="link"
        href="mailto:hello@mariocampione.ch?subject=Hey there Mario"
        target="_blank"
        rel="noopener noreferrer"
      >
        reach out
      </a>
    </p>
  </div>
);

const Portfolio = () => (
  <div className="verbose clients">
    <h2 className="heading">Companies I've proudly helped</h2>
    <ul className="list">
      <li className="item">
        <a href="https://ricardo.ch/" target="_blank" rel="noopener noreferrer">
          <img
            src="clients/ricardo.svg"
            alt="Ricardo AG"
            className="client-logo"
          />
        </a>
      </li>
      <li className="item">
        <a
          href="https://postfinance.ch/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="clients/postfinance.png"
            alt="© PostFinance Ltd 2017, all rights reserved"
            className="client-logo"
          />
        </a>
      </li>
      <li className="item">
        <a
          href="https://digital-architects-zurich.ch/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="clients/daz.png"
            alt="Digital Architects Zurich"
            className="client-logo"
          />
        </a>
      </li>
      <li className="item">
        <a href="https://sly.ch" target="_blank" rel="noopener noreferrer">
          <img src="clients/sly.svg" alt="Sly GmbH" className="client-logo" />
        </a>
      </li>
    </ul>
  </div>
);

const Copyright = () => (
  <div className="copyright">
    &copy; {new Date().getFullYear()} mario campione gmbh
  </div>
);

const App = () => (
  <div className="app">
    <Logo />

    <Intro />

    <MyJob />

    <WhereILive />

    <JobDetails />

    <Skills />

    <ReachOut />

    <Portfolio />

    {/* <FreeTime /> */}

    <Copyright />
  </div>
);

export default App;
